import React, { useEffect, useState } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import "./index.css";

function SurveyComponent() {
  const [ref, setRef] = useState("");
  const [surveyModel, setSurveyModel] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const refParam = queryParams.get("ref");
    setRef(refParam);

    const fetchSurveyJson = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_N8N_WEBHOOK_URL}?ref=${refParam}`);
        const data = await response.json();
        console.log('JSON recibido:', data);
    
        if (data && Object.keys(data).length > 0) {
          // Verifica que el JSON tenga la estructura correcta antes de crear el modelo
          console.log('JSON para Survey:', { ...data, title: `Referencia: ${refParam}` });
    
          const updatedJson = { ...data, title: `Referencia: ${refParam}` };
          const survey = new Model(updatedJson);
    
          survey.onComplete.add((sender, options) => {
            const surveyData = JSON.stringify(sender.data, null, 3);
            console.log(surveyData);

            // Enviar los datos al padre (ventana principal)
            if (window.parent) {
              window.parent.postMessage({
                eventType: 'surveyCompleted',
                data: surveyData,
              }, '*'); // '*' permite el envío a cualquier origen, cambiarlo si es necesario
            }
          });
    
          setSurveyModel(survey);
        } else {
          console.error("No se encontraron datos para la referencia proporcionada.");
        }
      } catch (error) {
        console.error("Error al obtener el JSON desde Directus:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSurveyJson();
  }, []);

  return (
    <div>
      {loading ? <div>Loading...</div> : (surveyModel ? <Survey model={surveyModel} /> : <div>Error al cargar el configurador</div>)}
    </div>
  );
}

export default SurveyComponent;
